let isMobile = window.innerWidth < 1024

// Define an object that will be used to draw plus signs
let Plus = function () {
    this.x = 0;
    this.y = 0;

    this.top = 0;
    this.left = 0;

    this.height = 0;
    this.width = 0;
    this.scale = 1;

    this.color = '#117DCC';
};

//Add draw method to the object
Plus.prototype.draw = function (ctx, x, y) {
    ctx.save();
    ctx.beginPath();
    ctx.setTransform(
        this.scale,
        0,
        0,
        this.scale,
        this.left + this.x,
        this.top + this.y
    );

    ctx.arc(0, 0, 1, 0, 2 * Math.PI);
    ctx.fillStyle = this.color;
    ctx.fill();
    ctx.closePath();
    ctx.restore();
};

let c = document.getElementById("c");
let context = c.getContext("2d");

fitToContainer(c);

function fitToContainer(canvas) {
    canvas.style.width = '100%';
    // canvas.style.height = 580 + 'px';
    canvas.style.height = '100%';
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
}

let signs = [];
let mouse = {x: 0, y: 0};
let gridLength = 40;
if (isMobile) {
    gridLength = 20
}
let mouseOver = false;
let mouseMoved = false;

// Create sign grid using 2D array
for (let i = 0; i < gridLength; i++) {
    signs[i] = [];
    for (let j = 0; j < gridLength; j++) {
        let min = Math.min(c.width, c.height);
        signs[i][j] = new Plus();
        signs[i][j].left = c.width / (gridLength + 1) * (i + 1);
        signs[i][j].top = c.height / (gridLength + 2) * (j + 2);
        signs[i][j].width = 2;
        signs[i][j].height = 2;

        // Cut dots from grid for text
        let iStart;
        let iEnd;
        let jStart;
        let jEnd;

        if(!isMobile) {
            iStart = 0;
            iEnd = 24;
            jStart = 11;
            jEnd = 27;
        } else {
            iStart = 0;
            iEnd = 15;
            jStart = 5;
            jEnd = 13;
        }

        // default
        if((i >= iStart && i <= iEnd) &&  (j >= jStart && j <= jEnd)) {
            signs[i][j].color = 'transparent';
        }
    }
}


TweenLite.ticker.addEventListener("tick", draw);

function draw(color) {
    context.clearRect(0, 0, c.width, c.height);

    if (mouseOver && mouseMoved) {
        calculateSigns();
        mouseMoved = false;
    }

    for (let i = 0; i < gridLength; i++) {
        for (let j = 0; j < gridLength; j++) {
            let sign = signs[i][j];
            sign.draw(context);
        }
    }
}

function calculateSigns() {
    for (let i = 0; i < gridLength; i++) {
        for (let j = 0; j < gridLength; j++) {
            let sign = signs[i][j];
            let hyp = Math.min(c.width, c.height) / gridLength * 2;
            let d = dist([sign.left, sign.top], [mouse.x, mouse.y]);
            let ax = mouse.x - sign.left;
            let ay = mouse.y - sign.top;
            let angle = Math.atan2(ay, ax);
            if (d < hyp + sign.width) {
                hyp = d;
                TweenMax.to(sign, 0.3, {scale: 2.5});
            } else {
                TweenMax.to(sign, 0.3, {scale: 1});
            }

            TweenMax.to(sign, 0.3, {
                x: Math.cos(angle) * hyp,
                y: Math.sin(angle) * hyp
            });
        }
    }
}

c.addEventListener("mousemove", mouseMove);
c.addEventListener("touchmove", mouseMove);

function mouseMove(e) {
    if (e.targetTouches && e.targetTouches[0]) {
        e = e.targetTouches[0];
    }
    let rect = c.getBoundingClientRect();
    mouse.x = e.clientX - rect.left;
    mouse.y = e.clientY - rect.top;
    mouseMoved = true;
}

c.addEventListener("mouseenter", function () {
    mouseOver = true;
});

c.addEventListener("touchstart", function (e) {
    mouseOver = true;
    mouseMove(e);
});

c.addEventListener("mouseleave", mouseLeave);
c.addEventListener("touchend", mouseLeave);

function mouseLeave() {
    mouseOver = false;

    for (let i = 0; i < gridLength; i++) {
        for (let j = 0; j < gridLength; j++) {
            let sign = signs[i][j];
            if (!mouseOver) {
                TweenMax.to(sign, 0.3, {x: 0, y: 0, scale: 1});
            }
        }
    }
}

window.addEventListener("resize", function () {
    let container = document.getElementsByClassName('banner__top-content')[0];
    c.width = container.offsetWidth;
    c.height = container.offsetHeight;

    for (let i = 0; i < gridLength; i++) {
        for (let j = 0; j < gridLength; j++) {
            let min = Math.min(c.width, c.height);
            sign = signs[i][j];
            sign.left = c.width / (gridLength + 1) * (i + 1);
            sign.top = c.height / (gridLength + 1) * (j + 1);
            sign.width = min / 50;
            sign.height = min / 50;
        }
    }
});

function dist([x1, y1], [x2, y2]) {
    let dx = x1 - x2;
    let dy = y1 - y2;
    return Math.sqrt(dx * dx + dy * dy) || 1;
}
